<template>
    <div class="justify-content-center align-items-center w-100 px-1">
        <header class="jumbotron">
            <div class="inline-flex">
                <h3>
                    <strong>User </strong>List
                </h3>
                <div class="float-right"><button type="button" class="btn btn-dark" data-toggle="modal" data-target="#addStaff">Add User</button></div>
            </div>
        </header>
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Restaurant</th>
                    <th scope="col">Role</th>
                    <th scope="col">Edit</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(user,index) in user_list" :key="index">
                    <td>{{user.first_name}} {{user.last_name}}</td>
                    <td>{{user.email}}</td>
                    <td>{{idToRestaurant(user.restaurant_id)}}</td>
                    <td><ul>  <li v-for="(role,index) in user.roles" :key="index">{{capitalizeFirstLetter(role.name)}}</li></ul></td>
                    <td class="justify-content-center align-items-center"><font-awesome-icon icon="user-edit" @click="resolveEditor(user.id)"/></td>
                </tr>
            </tbody>
        </table>
        <div class="modal" id="addStaff" tabindex="-1" role="dialog" aria-labelledby="addStaffLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Add a Staff Member</h5>
                        <button type="button" class="close" id="close" data-dismiss="modal" aria-label="Close" @click="resetModal()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form>
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="inputEmail4">First Name</label>
                                    <input type="text" class="form-control" id="inputFName" placeholder="John" v-model="userModal.first_name">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="inputPassword4">Last Name</label>
                                    <input type="text" class="form-control" id="inputLName" placeholder="Doe" v-model="userModal.last_name">
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="inputAddress2">Email</label>
                                <input type="email" class="form-control" id="inputEmail" aria-describedby="emailHelp" placeholder="Enter email" v-model="userModal.email">
                            </div>
                             <div class="form-group">
                            <label for="exampleFormControlSelect1">Role</label>
                            <select class="form-control" id="userRole" v-model="userModal.role">
                              <option v-for="(role,index) in roles" :key="role.id" :value="role.id">{{capitalizeFirstLetter(role.name)}}</option>
                            </select>
                          </div>
                          <div class="form-group">
                            <label for="inputRestaurant">Restaurant</label>
                            <select class="form-control" id="userRole" v-model="userModal.restaurant_id">
                            <option :value="null">All Restaurants</option>
                              <option v-for="(restaurant,index) in restaurants" :key="restaurant.id" :value="restaurant.id">{{restaurant.name}}</option>
                            </select>
                          </div>
                             <div class="form-group">
                                <label for="inputPassword">Password</label>
                                <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password" v-model="userModal.password">
                              </div>
                        </form>
                    </div>
                    <div class="modal-footer text-center">
                        <button  type="button" class="btn btn-primary" @click="resetModal()">Cancel</button>
                        <button  type="button" class="btn btn-primary" @click="addUser()">Add User</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import UserService from '../services/user.service';

export default {
    name: 'Users',
    data() {
        return {
            user_list: [],
            roles: [],
            positions: [],
            areas: [],
            restaurants: [],
            userModal: {
                first_name: null,
                last_name: null,
                email: null,
                restaurant_id: null,
                role: null,
                password:null,
            }
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    methods: {
        resolveEditor(id){
            this.$router.push(`/users/edit/${id}`);
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        idToPosition(id) {
            if (this.positions.length > 0) {

                return this.positions.filter(function(el) {
                    return el.id == id
                })[0].name
            } else {
                return ''
            }
        },
        idToRestaurant(id) {
            if (this.restaurants.length > 0) {
                let x = this.restaurants.filter(function(el) {
                    return el.id == id
                })[0] 
                return x ? x.name : "All"
            } else {
                return ''
            }
        },
        addUser() {
            let user = {
                first_name: this.userModal.first_name,
                last_name: this.userModal.last_name,
                email: this.userModal.email,
                restaurant_id: this.userModal.restaurant_id,
                role: this.userModal.role,
                password: this.userModal.password,
            }
            UserService.addUser(user).then(
              response=>{
                this.resetModal()
                UserService.getUserList().then(
            response => {
                this.user_list = response.data;
            },
            error => {
                this.content =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );  
              },
              error => {
                this.content =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
              })
        },

    resetModal(){
            document.getElementById('close').click();
            this.userModal = {
                first_name: null,
                last_name: null,
                email: null,
                restaurant_id: null,
                role: null,
            }
        }
    },
    mounted() {
        if (!this.currentUser) {
            this.$router.push('/login');
        }
        UserService.getUserList().then(
            response => {
                this.user_list = response.data;
            },
            error => {
                this.content =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );
        UserService.getRoleList().then(
            response => {
                this.roles = response.data;
            },
            error => {
                this.content =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );
        UserService.getRestaurantsList().then(
            response => {
                this.restaurants = response.data;
            },
            error => {
                this.content =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );
    }
};
</script>